<template>
  <div>
    <a-modal v-model="modalVisible" title="电梯审批" :width="600" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">取消</a-button>
        <a-button type="primary" @click="confirm">确认</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:14}">
        <a-form-model-item label="检测机构" prop="examinedepid">
          <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions" v-model="deptCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择检测机构" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="检测人员" prop="examineperson">
          <a-input v-model.trim="formDatas.examineperson" />
        </a-form-model-item>
        <a-form-model-item label="检测时间" prop="examinetime">
          <a-date-picker v-model="examinetimeMoment" style="width: 100%;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="下次检测时间" prop="nextexaminetime">
          <a-date-picker v-model="nextexaminetimeMoment" style="width: 100%;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="电梯检测" prop="result">
          <a-select v-model="formDatas.result">
            <a-select-option value="0">合格</a-select-option>
            <a-select-option value="1">不合格</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="描述" prop="resultdesc">
          <a-textarea v-model.trim="formDatas.resultdesc" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import deptselect from '@/mixins/deptselect'
import { addExamineinfo } from 'A/jcgn'
export default {
  mixins: [deptselect],
  props: {
    visible: {
      default: false
    },
    liftid: {
      default: ''
    }
  },
  data() {
    return {
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formDatas: {
        examinedepid: '',
        examineperson: '',
        examinetime: '',
        nextexaminetime: '',
        result: '0',
        resultdesc: '',
      },
      formRules: {
        examinedepid: [{required: true, message: '请选择检测机构'}],
        examineperson: [{required: true, message: '请输入检测人员'}],
        examinetime: [{required: true, message: '请选择检测时间'}],
        nextexaminetime: [{required: true, message: '请选择下次检测时间'}],
        result: [{required: true, message: '请选择检测结果'}],
      },
      examinetimeMoment: null,
      nextexaminetimeMoment: null,
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
    deptCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.examinedepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('examinedepid');
      }else {
        this.formDatas.examinedepid = ''
      }
    },
    examinetimeMoment(val) {
      if(val) {
        this.formDatas.examinetime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('examinetime')
      }else {
        this.formDatas.examinetime = '';
      }
    },
    nextexaminetimeMoment(val) {
      if(val) {
        this.formDatas.nextexaminetime = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('nextexaminetime')
      }else {
        this.formDatas.nextexaminetime = '';
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    resetDatas() {
      this.deptCascaderSelected = [];
      this.examinetimeMoment = null;
      this.nextexaminetimeMoment = null;
      this.$refs.modalForm.resetFields();
    },
    confirm() {
      // success fail
      this.$refs.modalForm.validate(valid => {
        if(valid) {
          this.showLoading();
          let params = {
            ...this.formDatas,
            liftid: this.liftid
          };
          addExamineinfo(params).then(res => {
            this.hideLoading();
            if(res && res.returncode == '0') {
              this.$message.success('操作成功');
              this.$emit('get-operation-result', 'success', '操作成功');
              this.modalVisible = false;
            }else {
              this.$message.error(res.errormsg||'操作失败');
            }
          })
        }else {
          return false;
        }
      })
    },
  }
}
</script>