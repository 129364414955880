<template>
  <div>
    <a-modal v-model="modalVisible" title="审批记录" :width="700" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">关闭</a-button>
      </template>
      <div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="approverecordid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
          <span slot="approvestatus" slot-scope="text">
            <span class="text">{{approvestatusMap[text]}}</span>
          </span>
          <span slot="approvetime" slot-scope="text">
            <span class="text">{{moment(text, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}}</span>
          </span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { getApproveListByCondition } from 'A/jcgn'
import approvestatusMap from '@/json/approvestatus'
export default {
  props: {
    visible: {
      default: false
    },
    liftid: {
      default: ''
    }
  },
  data() {
    return {
      moment,
      approvestatusMap,
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      modalVisible: false,
      tableLoading: false,
      tableColumns: [
        {
          title: '审批人',
          dataIndex: 'approveaccount',
          key: 'approveaccount',
          ellipsis: true,
        },
        {
          title: '审批状态',
          dataIndex: 'approvestatus',
          key: 'approvestatus',
          ellipsis: true,
          scopedSlots: { customRender: 'approvestatus' }
        },
        {
          title: '审批时间',
          dataIndex: 'approvetime',
          key: 'approvetime',
          ellipsis: true,
          scopedSlots: { customRender: 'approvetime' }
        },
        {
          title: '审批描述',
          dataIndex: 'approvedesc',
          key: 'approvedesc',
          ellipsis: true,
        },
      ],
      tableData: [],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 5,
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    // this.getTableData();
  },
  methods: {
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        liftid: this.liftid,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getApproveListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
  }
}
</script>