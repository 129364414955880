<template>
  <div>
    <a-modal v-model="modalVisible" title="检测记录" :width="600" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">关闭</a-button>
      </template>
      <div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="liftid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
          <span slot="examinetime" slot-scope="value">{{moment(value, 'YYYYMMDD').format('YYYY-MM-DD')}}</span>
          <span slot="nextexaminetime" slot-scope="value">{{moment(value, 'YYYYMMDD').format('YYYY-MM-DD')}}</span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { findExamineListByCondition } from 'A/jcgn'
export default {
  props: {
    visible: {
      default: false
    },
    liftid: {
      default: ''
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      modalVisible: false,
      tableLoading: false,
      tableColumns: [
        {
          title: '检测机构',
          dataIndex: 'examinedepname',
          key: 'examinedepname'
        },
        {
          title: '检测人员',
          dataIndex: 'examineperson',
          key: 'examineperson'
        },
        {
          title: '检测时间',
          dataIndex: 'examinetime',
          key: 'examinetime',
          scopedSlots: { customRender: 'examinetime' }
        },
        {
          title: '下次检测时间',
          dataIndex: 'nextexaminetime',
          key: 'nextexaminetime',
          scopedSlots: { customRender: 'nextexaminetime' }
        },
      ],
      tableData: [],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 5,
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    // this.getTableData();
  },
  methods: {
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        liftid: this.liftid,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      findExamineListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
  }
}
</script>