<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="电梯注册码" prop="liftnum" style="width: 22%">
              <a-input v-model.trim="queryParams.liftnum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="区域" prop="areacode" style="width: 22%">
              <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="queryAreaSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="是否物联网" prop="linkiot" style="width: 22%">
              <a-select placeholder="请选择" v-model="queryParams.linkiot">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">无物联网</a-select-option>
                <a-select-option value="1">物联网</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="电梯救援码" prop="liftrescue" style="width: 22%">
              <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="安装地址" prop="adress" style="width: 22%">
              <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
            </a-form-model-item>
<!--            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepid">-->
<!--              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择使用单位" :change-on-select="true" :allow-clear="false"></a-cascader>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保单位" prop="maintenancedepid">-->
<!--              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='3')" v-model="maintenancedepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择维保单位" :change-on-select="true" :allow-clear="false"></a-cascader>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保组" prop="maintenancegroupid">-->
<!--              <a-select v-model="queryParams.maintenancegroupid">-->
<!--                <a-select-option v-for="(item, index) in maintenancegroupidOptions" :key="index" :value="item.maintenancegroupid">{{item.maintenancegroupname}}</a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-model-item>-->
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'||userInfo.usertype=='2'" label="使用单位" prop="userdepname" style="width: 22%">
              <a-input v-model="queryParams.userdepname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'||userInfo.usertype=='2'" label="维保单位" prop="maintenancedepname" style="width: 22%">
              <a-input v-model="queryParams.maintenancedepname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保组" prop="maintenancegroupname" style="width: 22%">
              <a-input v-model="queryParams.maintenancegroupname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="安装日期" prop="installendtime" style="width: 22%">
              <a-range-picker v-model="queryInstallDate"></a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="设备ID" prop="devicenum" style="width: 22%">
              <a-input v-model="queryParams.devicenum" placeholder="请输入"></a-input>
            </a-form-model-item>
<!--            <a-form-model-item label="正式数据" prop="formalflag" style="width: 22%">-->
<!--              <a-select placeholder="请选择" v-model="queryParams.formalflag">-->
<!--                <a-select-option value="">全部</a-select-option>-->
<!--                <a-select-option value="0">是</a-select-option>-->
<!--                <a-select-option value="1">否</a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-model-item>-->
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <template v-if="pageType=='draft'">
<!--              <a-button>导入模板</a-button>-->
<!--              <a-button style="margin-left: 8px;">导入</a-button>-->
              <a-button type="primary" style="margin-left: 8px;" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            </template>
<!--            <template v-else>-->
<!--              <a-button>导出</a-button>-->
<!--            </template>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="liftid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="checkstatus" slot-scope="value">
            <a-tag :color="approvestatuscolors[value]||''">{{approvestatusMap[value]||''}}</a-tag>
          </span>
            <span slot="isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':value=='2'?'red':''">{{value=='1'?'在线':value=='2'?'离线':'无设备'}}</a-tag>
          </span>
          <span slot="monitoring" slot-scope="value, record">
            <a-button type="primary" size="small" @click="operationClick({key:'monitoring-'+record.liftid})">实时监控</a-button>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <!-- <a-menu-item :key="'check-'+record.liftid">电梯审批</a-menu-item> -->
                <a-menu-item :key="'detail-'+record.liftid">详情</a-menu-item>
                <!-- <a-menu-item :key="'monitoring-'+record.liftid">实时监控</a-menu-item> -->
                <a-menu-item v-show="record.checkstatus!='1'" :key="'edit-'+record.liftid">修改</a-menu-item>
                <!-- <a-menu-item :key="'checklist-'+record.liftid">审批记录</a-menu-item> -->
                <!-- <a-menu-item :key="'examine-'+record.liftid">电梯检测</a-menu-item> -->
                <!-- <a-menu-item :key="'examinelist-'+record.liftid">检测记录</a-menu-item> -->
                <a-menu-item :key="'delete-'+record.liftid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-quick-jumper show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
    <!-- <check-modal :visible.sync="checkModalVisible" :liftid="operationLiftid" @get-operation-result="getTableData"></check-modal> -->
    <checklist-modal :visible.sync="checklistModalVisible" :liftid="operationLiftid"></checklist-modal>
    <examine-modal :visible.sync="examineModalVisible" :liftid="operationLiftid"></examine-modal>
    <examinelist-modal :visible.sync="examinelistModalVisible" :liftid="operationLiftid"></examinelist-modal>
  </page-container>
</template>

<script>
import { getItemFromArrayByKey, getTimeRange } from 'U'
import store from '@/store'
import { mapState } from 'vuex'
import moment from 'moment'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import approvestatusMap from '@/json/approvestatus'
import approvestatuscolors from '@/json/approvestatuscolors'
import { getMentenacegroupListByCondition } from 'A/xtpz.js'
import { getLiftinfoListByCondition, deleteLiftinfo, getLiftinfoById } from 'A/jcgn.js'
import addOrEditModal from './addOrEditModal'
import checklistModal from './checklistModal'
import examineModal from './examineModal'
import examinelistModal from './examinelistModal'
export default {
  name: 'elevatorArchives',
  mixins: [areaselect, deptselect, pagination],
  components: {
    addOrEditModal,
    // checkModal,
    checklistModal,
    examineModal,
    examinelistModal,
  },
  props: ['pageType'],
  data() {
    return {
      moment,
      approvestatusMap,
      approvestatuscolors,
      showAdvanced: false,
      queryParams: {
        liftnum: '',
        liftrescue: '',
        regesternum:'',
        userdepid: '',
        maintenancedepid: '',
        maintenancegroupid: '',
        userdepname:'',
        maintenancedepname:'',
        maintenancegroupname:'',
        adress: '',
        areacode: '',
        installstarttime: '',
        installendtime: '',
        linkiot:'',
        formalflag:'0',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      maintenancedepidCascaderSelected: [],
      maintenancegroupidOptions: [],
      tableColumns: [
        {
          title: '电梯注册代码',
          dataIndex: 'liftnum',
          key: 'liftnum',
          ellipsis: true,
          width:180,
        },
        {
          title: '电梯救援码',
          dataIndex: 'liftrescue',
          key: 'liftrescue',
          ellipsis: true,
          width:85,
        },
        {
          title: '登记编号',
          dataIndex: 'regesternum',
          key: 'regesternum',
          ellipsis: true,
          width:120,
        },
        {
          title: '安装地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        },
        {
          title: '使用单位',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '维保单位',
          dataIndex: 'maintenancedepname',
          key: 'maintenancedepname',
          ellipsis: true,
        },
        {
          title: '维保组名称',
          dataIndex: 'maintenancegroupname',
          key: 'maintenancegroupname',
          ellipsis: true,
        },
        {
          title: '审核状态',
          dataIndex: 'checkstatus',
          key: 'checkstatus',
          ellipsis: true,
          width: this.pageType=='draft'?'':'0px',
          scopedSlots: { customRender: 'checkstatus' }
        },
        {
          title: '设备情况',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          align: "center",
          width: 80,
          scopedSlots: {customRender: 'isonline'}
        },
        {
          title: '',
          key: 'monitoring',
          align: 'center',
          width: 85,
          scopedSlots: { customRender: 'monitoring' }
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      operationLiftid: '',
      // checkModalVisible: false,
      checklistModalVisible: false,
      examineModalVisible: false,
      examinelistModalVisible: false,
      counts:'',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      if(this.pageType == 'draft') {
        return '电梯草稿';
      }else {
        return '电梯档案';
      }
    },
    breadcrumb() {
      return [
        {
          name: '基础功能管理',
          path: ''
        },
        {
          name: '电梯档案管理',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch: {
    'queryParams.linkiot':function (){
      this.getTableData()
    },
    'queryParams.formalflag':function (){
      this.getTableData()
    },
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.installstarttime = start;
      this.queryParams.installendtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
      }else {
        this.queryParams.userdepid = '';
      }
    },
    maintenancedepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.maintenancedepid = val[val.length-1];
        this.initMaintenancegroupidOptions();
      }else {
        this.queryParams.maintenancedepid = '';
        this.maintenancegroupidOptions = [];
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    const keepAlivePages = ['elevatorArchivesDetail', 'timeMonitoring'];
    if(!toTab || keepAlivePages.indexOf(from.name)<0) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = null;
      this.userdepidCascaderSelected = [];
      this.maintenancedepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        checkstatus: this.pageType=='draft' ? '0|3' : '2',
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getLiftinfoListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.liftid);
        },
        onCancel() {},
      });
    },
    delete(liftid) {
      this.showLoading();
      if(liftid) {
        let params = {
          liftid
        };
        deleteLiftinfo(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      if(type == 'detail') {
        let prePages = [
          {
            name: '基础功能',
            path: ''
          }
        ];
        if(this.pageType == 'draft') {
          prePages.push({
            name: '电梯草稿',
            path: 'elevatorDraft'
          })
        }else {
          prePages.push({
            name: '电梯档案',
            path: 'elevatorArchives'
          })
        }
        this.$router.push({
          name: 'elevatorArchivesDetail',
          params: {
            liftid: record.liftid,
            prePages: prePages
          }
        })
      }else {
        this.modalShowType = type;
        if(type == 'add') {
          this.modalDetailData = null;
        }else {
          this.modalDetailData = record;
        }
        this.modalVisible = true;
      }
    },
    initMaintenancegroupidOptions() {
      let params = {
        maintenancedepid: this.queryParams.maintenancedepid,
      }
      getMentenacegroupListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.maintenancegroupidOptions = res.item;
        }
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let liftid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'liftid', liftid);
      if(type == 'detail') {
        this.showModal('detail', liftid, record)
      }else if(type == 'monitoring') {
        let prePages = [
          {
            name: '基础功能',
            path: ''
          }
        ];
        if(this.pageType == 'draft') {
          prePages.push({
            name: '电梯草稿',
            path: 'elevatorDraft'
          })
        }else {
          prePages.push({
            name: '电梯档案',
            path: 'elevatorArchives'
          })
        }
        let params = {
          liftid: record.liftid
        };
        this.showLoading();
        getLiftinfoById(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            if(res.item.deviceinfos && res.item.deviceinfos.length) {
              this.$router.push({
                name: 'timeMonitoring',
                params: {
                  liftid: record.liftid,
                  liftnum: res.item.liftnum,
                  address: res.item.adress,
                  liftcode: res.item.liftcode,
                  deviceinfos: res.item.deviceinfos,
                  prePages: prePages
                }
              })
            }else {
              this.$message.error('该电梯无物联网设备');
            }
          }
        })
      }else if(type == 'edit') {
        this.showModal('edit', liftid, record)
      }else if(type == 'delete') {
        this.deleteConfirm(liftid, record)
      }else {
        this.operationLiftid = liftid;
        this[type+'ModalVisible'] = true;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>